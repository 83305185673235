
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import Entities from "@/components/base/common/Entities.vue";
import CookieService from "@/core/services/CookieService";
import { COOKIE_KEY, OPERATOR, SEARCH_ENTITY } from "@/core/config/Constant";
import { ISearchField } from "@/core/entity/ISearchField";
import { HandlePanel, SearchEntityEvent } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { ICase } from "@/core/entity/ICase";
import ViewCard from "@/views/case/ViewCard.vue";
import Panel from "@/components/base/panel/Panel.vue";
import CaseStatus from "@/views/case/CaseStatus.vue";
import Row from "@/components/base/common/Row.vue";
import RecentCards from "@/components/base/common/RecentCards.vue";

export default defineComponent({
  name: "Recent",
  components: {RecentCards, Row, CaseStatus, Panel,  ViewCard, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Recent Cases',
        [
          {link: false, router: '', text: 'Recent Cases'}
        ]
      )
    })

    const ids = ref("")
    const recentList =  CookieService.get(COOKIE_KEY.RECENT_CASES);
    if (recentList) {
      ids.value = JSON.parse(recentList).join(",")
    }
    const filter: ISearchField[] = [
      {column: 'caseId', type: 'HIDDEN', operator: OPERATOR.IN, value: ids.value? ids.value : '0', name: ''},
    ]
    const caseViewPage = computed(() => store.state.CaseModule.caseViewPage);
    const caze = computed<ICase>(() => store.state.CaseModule.case);
    return {
      ...SearchEntityEvent(Actions.CASE_SEARCH, filter, ['all'], [], SEARCH_ENTITY.CASE_VIEW),
      caseViewPage,
      ...HandlePanel(Actions.LOAD_CASE),
      caze,
    }
  }
})
